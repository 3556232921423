import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {

  slideOptions = {
    speed: 400,
    slidesPerView: 1.5,
    spaceBetween: 30,
    centeredSlides: true,
    autoHeight: true,
    loop: true,
    loopedSlides: 4
  };

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  skip(){
    localStorage["WELCOME"] = true;
    this.modalController.dismiss();
  }
}
