<ion-content class="transparent-background ion-padding">
  <ion-grid *ngIf="!showCard">
    <ion-row>
      <ion-col size="12">
        <ion-img src="../../../assets/img/loyatly-card-placeholder.jpg"></ion-img>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="loyalty-card">
        <form [formGroup]="loyaltyForm" (ngSubmit)="validateForm()">
          <div class="field-header">
            <ion-label>Which partner are you at?</ion-label>
          </div>
          <br />
          <div class="select">
            <ion-select formControlName="partner" (ionChange)="onPartnerChange($event)"  [interfaceOptions]="{ header: 'Partners'}">
              <ion-select-option text-wrap  *ngFor="let x of partners" value="{{x.partner}}">{{x.partner}}</ion-select-option>
            </ion-select>
          </div>
          <br />
          <div class="field-header">
            <ion-label>Select the special</ion-label>
          </div>
          <br />
          <div class="select">
            <ion-select formControlName="special" (ionChange)="onSpecialChange($event)" [interfaceOptions]="{ header: 'Special'}">
              <ion-select-option  *ngFor="let x of special" >{{x.special}}</ion-select-option>
            </ion-select>
          </div>
          <br />
          <div class="field-header">
            <ion-label>Enter the amount you about to spend</ion-label>
          </div>
          <br />
          <div class="number">
            <ion-input mode="ios" formControlName="amount" type="number" placeholder="450"
              [class.invalid]="!loyaltyForm.controls.amount.untouched && !loyaltyForm.controls.amount.valid && (loyaltyForm.controls.amount.dirty || submitAttempt)">
            </ion-input>
          </div>
          <div class="ion-text-center"
            *ngIf="!loyaltyForm.controls.amount.untouched && !loyaltyForm.controls.amount.valid  && (loyaltyForm.controls.amount.dirty || submitAttempt)">
            <ion-text color="danger">
              <small>Please enter a valid amount.</small>
            </ion-text>
          </div>
          <br />
          <ion-button mode="ios" type="submit" fill="outline" [disabled]="!loyaltyForm.valid" color="grey"
            expand="block" size="large">GENERATE LOYALTY CARD</ion-button>
        </form>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!generatedQrCode">
      <ion-col size="12">
        <div class="ion-text-center">
          <ion-button (click)="generateQRCode()" fill="clear" color="primary">GENERATE
            QR CODE</ion-button>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="generatedQrCode"  no-padding justify-content-center align-items-center> 
      <ion-col size="12">
        <div class="ion-text-center present-text">
          <div class="image">
            <ion-img [src]="generatedQrCodeURL"></ion-img>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="showCard">
    <ion-row>
      <ion-col size="12">
        <div class="ion-text-center present-text">
          Present the card to the Alliance partner.
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <div class="ion-text-center submited-card">
          <ion-img [src]="loyaltyCard"></ion-img>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <form [formGroup]="ratingForm" (ngSubmit)="validateRatingForm()">
          <div class="ion-text-start">
            <ion-label>Rate the Alliance partner</ion-label>
          </div>
          <br />
          <div class="text">
            <ion-input mode="ios" formControlName="rating" type="text" placeholder="Say something about the partner."
              [class.invalid]="!ratingForm.controls.rating.untouched && !ratingForm.controls.rating.valid && (ratingForm.controls.rating.dirty || submitAttempt)">
            </ion-input>
          </div>
          <br />
          <div class="ion-text-center">
            <rating formControlName="rate" readonly="false" size="default">
            </rating>
          </div>
          <br />
          <div class="ion-text-center">
            <ion-button mode="ios"  [disabled]="!ratingForm.valid" type="submit" color="primary">SUBMIT</ion-button>
          </div>
        </form>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <div class="ion-text-center">
          <ion-button (click)="close()" fill="clear" color="medium">Close</ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>