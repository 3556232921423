<ion-toolbar mode="md" [color]="dark ? 'black' : 'white'">
  <ion-buttons slot="start">
    <ion-back-button></ion-back-button>
  </ion-buttons>
  <ion-title>
    <span *ngIf="title">{{title}}</span>
    <span *ngIf="!title">
      <img *ngIf="!dark" class="logo" src="/assets/img/crescent_lifestyle_logo_header.svg" />
      <img *ngIf="dark" class="logo" src="/assets/img/crescent_lifestyle_logo.svg" />
    </span>
  </ion-title>
  <ion-buttons slot="end">
    <ion-button class="live-button" *ngIf="liveStreamMasjid" (click)="livestream()">
      <ion-img src="/assets/img/LIVE.svg"></ion-img>
    </ion-button>
    <ion-button (click)="compass()">
      <ion-img src="/assets/img/qibla_header_icon.svg"></ion-img><ion-text color="medium" *ngIf="prayerTime">&nbsp;<b>{{prayerTime}}</b></ion-text>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
<ion-toolbar mode="md" *ngIf="showDate" color="secondary" class="date-toolbar">
  <ion-title size="small" class="ion-text-center">
    <ion-text color="primary">{{iDate}}</ion-text>
    <ion-text color="primary">&nbsp;•&nbsp;</ion-text>
    <ion-text color="medium">{{gDate | date:'dd MMMM yyyy'}}</ion-text>
  </ion-title>
</ion-toolbar>