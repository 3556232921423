import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

//import { Geolocation } from '@ionic-native/geolocation/ngx';
//import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { EventsService } from './services/events.service';
import { LoadingService } from './services/loading.service';
import { ApiService } from './services/api.service';
import { MenuComponentModule } from './components/menu/menu.module';
import { PrayerTimeService } from './services/prayer-time.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ModalComponentModule } from './components/card/modal/modal.module';
import { LoyaltyCardComponentModule } from './components/loyalty-card/loyalty-card.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    MenuComponentModule,
    BrowserModule, 
    HttpClientModule,
    IonicModule.forRoot(), 
    AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    ApiService,
    PrayerTimeService,
    LoadingService,
    EventsService,
    //Geolocation,
    //LaunchNavigator,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
