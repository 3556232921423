import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from '../models/message';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private inboxMessageDeletedEventSource = new Subject<Message>();
  private inboxCountUpdatedEventSource = new Subject<number>();
  private userLoginEventSource = new Subject<boolean>();
  private tabChangedEventSource = new Subject<string>();

  constructor() { }


  // Observable string streams
  inboxMessageDeletedEvent$ = this.inboxMessageDeletedEventSource.asObservable();
  inboxCountUpdatedEvent$ = this.inboxCountUpdatedEventSource.asObservable();
  userLoginEvent$ = this.userLoginEventSource.asObservable();
  tabChangedEvent$ = this.tabChangedEventSource.asObservable();
 
  // Service message commands
  inboxMessageDeleted(item: Message) {
    this.inboxMessageDeletedEventSource.next(item);
  }

  inboxCountUpdated(amount: number) {
    this.inboxCountUpdatedEventSource.next(amount);
  }

  userLogin(login: boolean) {
    this.userLoginEventSource.next(login);
  }

  tabChanged(tab: string) {
    this.tabChangedEventSource.next(tab);
  }
}
