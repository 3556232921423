<ion-header>
  <app-header [dark]="true"></app-header>
</ion-header>
<ion-content>
  <ion-list class="ion-no-padding" lines="full">
    <ion-menu-toggle>
    <!-- <ion-item button detail="false" (click)="goto('/favourites')" color="black">
      <ion-label>MY FAVOURITES</ion-label>
    </ion-item> -->
    <ion-item button detail="false" (click)="goto('/connect', true)" color="black">
      <ion-label>CONNECT</ion-label>
    </ion-item>
    <ion-item button detail="false" (click)="goto('/save', true)" color="black">
      <ion-label>SAVE</ion-label>
    </ion-item>
    <ion-item button detail="false" (click)="goto('/win', true)" color="black">
      <ion-label>WIN</ion-label>
    </ion-item>
    <ion-item button detail="false" (click)="goto('/give', true)" color="black">
      <ion-label>GIVE</ion-label>
    </ion-item>
    <ion-item button detail="false" (click)="goto('/alliance', true)" color="white">
      <ion-label>ALLIANCE PARTNERS</ion-label>
    </ion-item>
    <ion-item button detail="false" (click)="goto('/bookshelf')" color="white">
      <ion-label>BOOKSHELF</ion-label>
    </ion-item>
    <!-- <ion-item button detail="false" (click)="goto('/breaking-news')" color="white">
      <ion-label>BREAKING NEWS</ion-label>
    </ion-item>
    <ion-item button detail="false" (click)="goto('/classifieds')" color="white">
      <ion-label>CLASSIFIEDS</ion-label>
    </ion-item> -->
    <ion-item button detail="false" (click)="loyalty()" color="white">
      <ion-label>LOYALTY CARD</ion-label>
    </ion-item>
    <!-- <ion-item button detail="false" (click)="goto('/social-feed')" color="white">
      <ion-label>SOCIAL FEED</ion-label>
    </ion-item> -->
    <ion-item button detail="false" (click)="goto('/inbox')" color="black">
      <ion-label>MY INBOX</ion-label>
      <ion-badge *ngIf="inboxCount" slot="end" mode="ios" color="primary">{{inboxCount}}</ion-badge>
    </ion-item>
    <!-- <ion-item button detail="false" (click)="goto('/hajj')" color="black">
      <ion-label>SIGN UP FOR HAJJ</ion-label>
    </ion-item> -->
    <!-- <ion-item button detail="false" (click)="goto('/newsletter')" color="black">
      <ion-label>SIGN UP FOR OUR NEWSLETTER</ion-label>
    </ion-item> -->
    <!-- <ion-item button detail="false" (click)="goto('/payment-options')" color="black">
      <ion-label>PAYMENT OPTIONS</ion-label>
    </ion-item> -->
    <ion-item button detail="false" (click)="goto('/about')" color="black">
      <ion-label>ABOUT THE APP</ion-label>
    </ion-item>
  </ion-menu-toggle>
    <ion-item button detail="false" (click)="logout()" color="black">
      <ion-label>LOGOUT</ion-label>
    </ion-item>
  
  </ion-list>
  <br />
  <br />
  <br />

</ion-content>