import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'connect', pathMatch: 'full' },
  {
    path: 'save',
    loadChildren: () => import('./pages/save/save.module').then( m => m.SavePageModule)
  },
  {
    path: 'win',
    loadChildren: () => import('./pages/win/win.module').then( m => m.WinPageModule)
  },
  {
    path: 'connect',
    loadChildren: () => import('./pages/connect/connect.module').then( m => m.ConnectPageModule)
  },
  {
    path: 'give',
    loadChildren: () => import('./pages/give/give.module').then( m => m.GivePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update/update.module').then( m => m.UpdatePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'compass',
    loadChildren: () => import('./pages/compass/compass.module').then( m => m.CompassPageModule)
  },
  {
    path: 'inbox/section/:id',
    loadChildren: () => import('./pages/inbox-messages/inbox-messages.module').then( m => m.InboxMessagesPageModule)
  },
  {
    path: 'inbox/:id',
    loadChildren: () => import('./pages/inbox-item/inbox-item.module').then( m => m.InboxItemPageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./pages/inbox/inbox.module').then( m => m.InboxPageModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then( m => m.LandingPageModule)
  },
  {
    path: 'bookshelf',
    loadChildren: () => import('./pages/bookshelf/bookshelf.module').then( m => m.BookshelfPageModule)
  },
  {
    path: 'bookshelf/all',
    loadChildren: () => import('./pages/bookshelf-all/bookshelf-all.module').then( m => m.BookshelfAllPageModule)
  },
  {
    path: 'bookshelf/category/:id',
    loadChildren: () => import('./pages/bookshelf-category/bookshelf-category.module').then( m => m.BookshelfCategoryPageModule)
  },
  {
    path: 'bookshelf/:id',
    loadChildren: () => import('./pages/bookshelf-item/bookshelf-item.module').then( m => m.BookshelfItemPageModule)
  },
  {
    path: 'connect/details/:type',
    loadChildren: () => import('./pages/connect-details/connect-details.module').then( m => m.ConnectDetailsModule)
  },

  {
    path: 'connect/location/:type',
    loadChildren: () => import('./pages/connect-locations/connect-locations.module').then( m => m.ConnectLocationsModule)
  },
  {
    path: 'give/item',
    loadChildren: () => import('./pages/give/items/item.module').then( m => m.ItemPageModule)
  },
  {
    path: 'hajj',
    loadChildren: () => import('./pages/hajj/hajj.module').then( m => m.HajjPageModule)
  },
  {
    path: 'alliance',
    loadChildren: () => import('./pages/alliance-partners/alliance-partners.module').then( m => m.AlliancePartnersPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
