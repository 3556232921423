import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MenuComponent } from './menu.component';
import { HeaderComponentModule } from '../header/header.module';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HeaderComponentModule,
    GoogleMapsModule,
    ReactiveFormsModule,
  ],
  declarations: [MenuComponent],
  exports: [MenuComponent]
})
export class MenuComponentModule {}
