import { Injectable } from '@angular/core';
import { PrayerTime as PrayerTimeResult } from 'src/app/models/prayer-time-result';
import { PrayerTime } from 'src/app/models/prayer-time';

@Injectable({
  providedIn: 'root'
})
export class PrayerTimeService {

  constructor() { }


  public getNextPrayerTime(pt: PrayerTime, map: boolean){
    
    if (this.isEarlierThan(pt.SehriDate))
    {
      return map ? `SEH ${pt.SehriDate}` : "sehri";
    }

    if (this.isEarlierThan(pt.FajrDate)) 
    {
      return map ? `FAJR ${pt.FajrDate}` : "fajr";
    }

    if (this.isEarlierThan(pt.SunriseDate))
    {
      return map ? `SUN ${pt.SunriseDate}` : "sunrise";
    }

    if (this.isEarlierThan(pt.ZawalDate))
    {
      return map ? `ZAW ${pt.ZawalDate}` : "zawaal";
    }

    if (this.isEarlierThan(pt.ZuhrDate))
    {
      return map ? `ZUHR ${pt.ZuhrDate}` : "zuhr";
    }

    if (this.isEarlierThan(pt.Asr_SDate))
    {
      return map ? `ASR(S) ${pt.Asr_SDate}` : "asr_s";
    }

    if (this.isEarlierThan(pt.Asr_HDate))
    {
      return map ? `ASR(H) ${pt.Asr_HDate}` : "asr_h";
    }

    if (this.isEarlierThan(pt.MagribDate))
    {
      return map ? `MAG ${pt.MagribDate}` : "maghrib";
    }

    if (this.isEarlierThan(pt.IshaDate))
    {
      return map ? `ISHA ${pt.IshaDate}` : "isha";
    }

    return "";
  }

  public getNextPrayerTimeResult(pt: PrayerTimeResult, map: boolean){
    
    if (this.isEarlierThan(pt.prayer_sehri))
    {
      return map ? `SEH ${pt.prayer_sehri}` : "sehri";
    }

    if (this.isEarlierThan(pt.prayer_fajr)) 
    {
      return map ? `FAJR ${pt.prayer_fajr}` : "fajr";
    }

    if (this.isEarlierThan(pt.prayer_sunrise))
    {
      return map ? `SUN ${pt.prayer_sunrise}` : "sunrise";
    }

    if (this.isEarlierThan(pt.prayer_zawal))
    {
      return map ? `ZAW ${pt.prayer_zawal}` : "zawaal";
    }

    if (this.isEarlierThan(pt.prayer_zuhr))
    {
      return map ? `ZUHR ${pt.prayer_zuhr}` : "zuhr";
    }

    if (this.isEarlierThan(pt.prayer_asr_s))
    {
      return map ? `ASR(S) ${pt.prayer_asr_s}` : "asr_s";
    }

    if (this.isEarlierThan(pt.prayer_asr_h))
    {
      return map ? `ASR(H) ${pt.prayer_asr_h}` : "asr_h";
    }

    if (this.isEarlierThan(pt.prayer_magrib))
    {
      return map ? `MAG ${pt.prayer_magrib}` : "maghrib";
    }

    if (this.isEarlierThan(pt.prayer_isha))
    {
      return map ? `ISHA ${pt.prayer_isha}` : "isha";
    }

    return "";
  }

  private isEarlierThan(time: string){
    var date = this.getPrayerTimeDate(time);
    var now = new Date();
    return date.getTime() > now.getTime();
  }
  
  private getPrayerTimeDate(time: string){
    return new Date(new Date().toDateString() + " " + time);
  }

  private addMinutes(date: Date, minutes: number){
    return new Date(date.getTime() + minutes*60000);
  }

  private formatTime(date: Date){

    let s = "";
    let h = date.getHours();
    let m = date.getMinutes();
    
    if(h < 10){
      s += '0';
    }
    s += h.toString();
    s += ':';

    if(m < 10){
      s += '0';
    }

    s += m.toString();
  
    return s;
  
  }


  public populatePrayerTime(pt : PrayerTime) : PrayerTime{

    let fajrDate = this.getPrayerTimeDate(pt.SehriDate);
    fajrDate = this.addMinutes(fajrDate, 5);
    pt.FajrDate = this.formatTime(fajrDate);

    let zuhrDate = this.getPrayerTimeDate(pt.ZawalDate);
    zuhrDate = this.addMinutes(zuhrDate, 5);
    pt.ZuhrDate = this.formatTime(zuhrDate);

    return pt;
  }

  public populatePrayerTimeResult(pt : PrayerTimeResult) : PrayerTimeResult{

    let fajrDate = this.getPrayerTimeDate(pt.prayer_sehri);
    fajrDate = this.addMinutes(fajrDate, 5);
    pt.prayer_fajr = this.formatTime(fajrDate);

    let zuhrDate = this.getPrayerTimeDate(pt.prayer_zawal);
    zuhrDate = this.addMinutes(zuhrDate, 5);
    pt.prayer_zuhr = this.formatTime(zuhrDate);

    return pt;
  }

}
