import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PrayerTimeResult, PrayerTime } from '../models/prayer-time-result';
import { SearchRequest } from '../models/search-request';
import { Mosque } from '../models/mosque';
import { CheckMemberRequest, CheckMemberResponse } from '../models/check-member';
import { CheckOTPRequest } from '../models/check-otp';
import { GetMessagesRequest } from '../models/get-messages-request';
import { Message } from '../models/message';
import { LocationResult } from '../models/location-result';
import { CrescentEvent } from '../models/crescent-event';
import { Funeral } from '../models/funeral';
import { Restaurant } from '../models/restaurant';
import { Version } from '../models/version';
import { LiveStreamMasjid } from '../models/live-stream-masjid';
import { MessageSection } from '../models/message-section';
import { CountPerSection } from '../models/count-per-section';
import { EventsService } from './events.service';
import { TrailUserModel } from '../models/trial-user-model';
import { Title } from '../models/title';
import { TrialUserRegister } from '../models/trial-user-register';
import { BookshelfCategory } from '../models/bookshelf-category';
import { BookshelfItem } from '../models/bookshelf-item';
import { BookshelfSearch } from '../models/bookshelf-search';
import { BeneficiaryPayout } from '../models/beneficiary-payout';
import { PageDetails } from '../models/page-details';
import { HajjApplication } from '../models/hajj-application';
import { HajjApplicationResponse } from '../models/hajj-application-response';
import { IdName } from '../models/id-name';
import { NewAlliancePartnerSearch } from '../models/new-alliance-partner-search';
import { NewAlliancePartner } from '../models/new-alliance-partner';
import { CustomerOffer, CustomerOfferRating } from '../models/customer-offer';
import { off } from 'process';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(protected http: HttpClient, private events: EventsService) { }

  protected get<O>(path: string, fresh: boolean = false, overriderUrl: boolean = false, headers: any = null): Observable<O> {

    let fullUrl = overriderUrl ? path : `${environment.apiUrl}/${path}`;

    let cache = JSON.parse(localStorage.getItem(fullUrl));

    if (!cache || fresh == true) {
      
      return this.http.get<O>(fullUrl, { headers: headers })
        .pipe(
          map((r) => {
            if (!environment.production)
            {
              console.log(`GET: ${path}:`);
              console.log(`<<<`);
              console.log(r);
            }
            localStorage.setItem(fullUrl, JSON.stringify(r));
            return r;
          })
        );
    }
    else {
      return Observable.create(observer => {
        if (!environment.production)
        {
          console.log(`FROM CACHE: ${path}:`);
          console.log(`<<<`);
          console.log(cache);
        }
        observer.next(cache);
        observer.complete();
      });
    }
  }

  protected post<I, O>(path: string, data: I): Observable<O> {
    return this.http.post<O>(`${environment.apiUrl}/${path}`, data).pipe(
      map((r) => {
        if (!environment.production)
        {
          console.log(`POST: ${path}:`);
          console.log(`>>>`);
          console.log(data);
          console.log(`<<<`);
          console.log(r);
        }
        return r;
      })
    );
  }

  protected clear(key: string) {
    localStorage.removeItem(key);
  }

  protected clearAll() {
    localStorage.clear();
  }


  public GetLiveStreamMajid(masjid: string, fresh: boolean = false): Observable<LiveStreamMasjid>{
    return this.get<LiveStreamMasjid>(`${environment.newApiUrl}/livestream/${masjid}/info`, fresh, true);
  }
  
  public GetVersionNumber(fresh: boolean = false): Observable<number>{
    return this.get<Version>('api/connect/getVersionNo').pipe(
      map((r) => {
        return r.version;
      })
    );
  }

  public CheckMember(data: CheckMemberRequest): Observable<CheckMemberResponse>{
    return this.post<CheckMemberRequest, CheckMemberResponse>('api/member/check', data);
  }

  public CheckOTP(data: CheckOTPRequest): Observable<string>{
    return this.post<CheckOTPRequest, string>('api/member/post', data);
  }

  public GetIslamicDate(fresh: boolean = false): Observable<string>{
    return this.get<string>('api/connect/islamicDate', fresh);
  }

  public GetUnreadMessages(fresh: boolean = false): Observable<number>{
    return this.get<number>(`api/connect/getUnreadMessages/${localStorage['GUID']}`, fresh);
  }

  public GetUnreadMessagesPerSection(fresh: boolean = false): Observable<CountPerSection[]>{
    return this.get<CountPerSection[]>(`api/connect/getUnreadMessagesPerSection/${localStorage['GUID']}`, fresh);
  }

  public GetMessageSections(): Observable<MessageSection[]>{
    return this.post<{}, MessageSection[]>('api/connect/messageSections', {});
  }

  public GetTopMessages(deviceIdentifier: string): Observable<Message[]>{
    return this.post<{}, Message[]>(`api/connect/retrieveTopMessages?deviceIdentifier=${deviceIdentifier}`, {});
  }

  public GetMessages(data: GetMessagesRequest): Observable<Message[]>{
    return this.post<GetMessagesRequest, Message[]>('api/connect/retrieveMessages', data);
  }

  public MarkMessageAsRead(id: number): Observable<{}>{
    return this.get<{}>(`api/connect/GetMessageAsRead/${id}`);
  }

  public MarkMessageAsDeleted(id: number): Observable<{}>{
    return this.get<{}>(`api/connect/GetMessageAsDeleted/${id}`);
  }

  public GetShareImageUrl(id: number): string{
    return `${environment.apiUrl}/Share.ashx?id=${id}&guid=${localStorage['GUID']}`;
  }
  
  public GetShareImage(id: number): Observable<string>{
    let headers = { responseType: 'text', 'Content-Type': 'text/plain' };
    let fullUrl = `${environment.apiUrl}/Share.ashx?id=${id}&guid=${localStorage['GUID']}`;
    return this.http.get(fullUrl, { headers: headers })
        .pipe(
          map((r) => {
            return r as string;
          })
        );


    //return this.get<string>(, false, true, headers);
  }

  public GetLoyaltyCardUrl(): string{
    return `${environment.apiUrl}/Card.ashx?id=${localStorage['GUID']}&portrait=true`;
  }

  public GetProvinces(): Observable<LocationResult[]>{
    return this.get<LocationResult[]>(`api/connect/GetProvinces`);
  }

  public GetAdminProvinces(): Observable<LocationResult[]>{
    return this.get<LocationResult[]>(`api/connect/GetAdminProvinces`);
  }

  public GetCities(provinceId: number, type: string = 'none'): Observable<LocationResult[]>{ //'none'|'prayertimes'|'mosques'|'funeral'|'event'
    return this.get<LocationResult[]>(`api/connect/GetCities/${provinceId}?type=${type}`);
  }

  public GetAdminCities(provinceId: number): Observable<LocationResult[]>{
    return this.get<LocationResult[]>(`api/connect/GetAdminCities?${provinceId}`);
  }

  public GetSuburbs(cityId: number, provinceId: number, type: string = 'none'): Observable<LocationResult[]>{ //'none'|'prayertimes'|'mosques'|'funeral'|'event'
    return this.get<LocationResult[]>(`api/connect/GetSuburbs/${cityId}?provinceId=${provinceId}&type=${type}`);
  }

  public GetEvents(request: SearchRequest): Observable<CrescentEvent[]>{
    return this.post<SearchRequest, CrescentEvent[]>(`api/connect/Events`, request);
  }

  public GetFunerals(request: SearchRequest): Observable<Funeral[]>{
    return this.post<SearchRequest, Funeral[]>(`api/connect/Funerals`, request);
  }

  public GetRestaurants(request: SearchRequest): Observable<Restaurant[]>{
    return this.post<SearchRequest, Restaurant[]>(`api/connect/Restaurants`, request);
  }

  public GetMosques(request: SearchRequest): Observable<Mosque[]>{
    return this.post<SearchRequest, Mosque[]>(`api/connect/Mosques`, request);
  }

  public GetAlliancePartners(request: SearchRequest): Observable<any[]>{
    return this.post<SearchRequest, any[]>(`api/connect/AlliancePartners`, request);
  }

  public GetPrayerTimes(request: SearchRequest): Observable<PrayerTimeResult[]>{
    return this.post<SearchRequest, PrayerTimeResult[]>(`api/connect/PrayerTimes`, request);
  }

  public GetPrayerTime(request: SearchRequest, current: boolean = false, fresh: boolean = false): Observable<PrayerTime>{

    let  cache = null;

    try{
      cache = JSON.parse(localStorage.getItem("CURRENT_PRAYER_TIME"));
    }
    catch
    {
    }

    if (cache && current && !fresh)
    {
      return Observable.create(observer => {
        
        observer.next(cache);
        observer.complete();
      });
    }
    else{
      return this.post<SearchRequest, PrayerTime>(`api/connect/PrayerTime`, request).pipe(
        map((r) => {
          if (current)
          {
            localStorage.setItem("CURRENT_PRAYER_TIME", JSON.stringify(r));
          }
          return r;
        }));
    }
    
  }
  public GetTitles(){
    return this.get<Title[]>('api/trialusers/titles');
  }

  public GetTrialUserTowns(provinceId?: number){
    let url = 'api/trialusers/towns/';
    if (provinceId)
    {
      url += provinceId.toString();
    }

    return this.get<TrailUserModel>(url);
  }

  public GetTrialUserMosques(townId: number){
    return this.get<TrailUserModel>(`api/trialusers/mosques/${townId}`);
  }

  public TrialUserRegister(data: TrialUserRegister)
  {
    return this.post<TrialUserRegister, TrialUserRegister>('api/trialusers/', data);
  }

  public GetBookshelfCategories(){
    return this.get<BookshelfCategory[]>('api/connect/GetBookshelfCategories');
  }

  public GetTopBookshelfItems(){
    return this.get<BookshelfItem[]>('api/connect/GetTopBookshelfItems');
  }

  public GetBookshelfItems(search: BookshelfSearch){
    return this.post<BookshelfSearch, BookshelfItem[]>('api/connect/GetBookshelfItems', search);
  }

  public GetBeneficiaryPayouts(){
    return this.get<BeneficiaryPayout[]>('api/connect/getPayoutPdf');
  }

  public GetPastBeneficiaries(){

    let data: PageDetails = {
      DomElement: '.box1-2',
      Page: 'http://www.crescentlifestyle.com/win/umrah-winners/'
    };

    return this.GetHtml(data);
  }

  private GetHtml(data: PageDetails){
    return this.post<PageDetails, string>('api/connect/HtmlPage', data);
  }

  public HajjApplication(data: HajjApplication){
    return this.post<HajjApplication, HajjApplicationResponse>('api/HajjFund/Application', data);
  }

  public GetAlliancePartnerTowns(){
    return this.get<IdName[]>('api/connect/GetAlliancePartnerTowns');
  }

  public GetNewAlliancePartnerCategories(){
    return this.get<IdName[]>('api/connect/GetNewAlliancePartnerCategories');
  }

  public GetNewAlliancePartners(search: NewAlliancePartnerSearch){
    return this.post<NewAlliancePartnerSearch, NewAlliancePartner[]>('api/connect/GetNewAlliancePartners', search);
  }

  public PostCustomerOffer(alliancePartnerOfferId: number, amount: number) {
    let offer: CustomerOffer = {
      AlliancePartnerOfferId: alliancePartnerOfferId,
      Amount: amount,
      Guid: localStorage['GUID']
    };

    return this.post<CustomerOffer, number>('api/connect/PostCustomerOffer', offer);
  }

  public PostCustomerOfferRating(customerOfferId: number, rating: number, comments: string)
  {
    let offer: CustomerOfferRating = {
      CustomerOfferId: customerOfferId,
      Rating: rating,
      Comments: comments,
      Guid: localStorage['GUID']
    };

    return this.post<CustomerOfferRating, {}>('api/connect/PostCustomerOfferRating', offer);
  }
}
