import { Component, OnInit, Input } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular";
import { ApiService } from "src/app/services/api.service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import {
  Plugins,
  MotionOrientationEventResult,
  PluginListenerHandle,
  GeolocationPosition,
} from "@capacitor/core";
import { MapInfoWindow, MapMarker, GoogleMap } from "@angular/google-maps";
import { CompileShallowModuleMetadata } from "@angular/compiler";
const { Motion, Geolocation } = Plugins;

@Component({
  selector: "app-loyalty-card",
  templateUrl: "./loyalty-card.component.html",
  styleUrls: ["./loyalty-card.component.scss"],
})
export class LoyaltyCardComponent implements OnInit {
  public rate: any;
  public mainLoyaltyCard: boolean = false;
  public showCard = false;
  public data: any;
  public partners: any[] = [];
  public special: any[] = [];
  public alliances: any[] = [];
  public loyaltyCard = "";
  public submitAttempt: boolean = false;
  public loyaltyForm: FormGroup;

  public generatedQrCode: boolean = false;
  public generatedQrCodeURL: string = "";
  public ratingForm: FormGroup = this.formBuilder.group({
    rate: ["", [Validators.required]],
    rating: ["", [Validators.required]],
  });

  search = {
    Skip: 0,
    Take: 100,
    Latitude: 0,
    Longitude: 0,
    FindClosest: true,
    FindNewest: false,
    FindExclusive: false,
    TownId: null,
    ProvinceId: null,
    CategoryId: null,
  };
  selectedSpecial: number;
  customerId: number;

  constructor(
    private navParams: NavParams,
    private apiService: ApiService,
    private modelController: ModalController,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    if (this.mainLoyaltyCard) {
      this.loyaltyForm = this.formBuilder.group({
        partner: ["", [Validators.required]],
        special: "",
        amount: ["", [Validators.required]],
      });
      Geolocation.getCurrentPosition().then((position) => {
        this.search.Latitude = position.coords.latitude;
        this.search.Longitude = position.coords.longitude;
        this.apiService.GetNewAlliancePartners(this.search).subscribe((x) => {
          this.alliances = [];
          this.alliances = x;
          this.partners = [];
          x.forEach((element) => {
            if (element.Offers) {
              this.partners.push({
                id: element.Id,
                partner: element.Name,
              });
            }
          });
        });
      });
    } else {
      this.loyaltyForm = this.formBuilder.group({
        partner: [this.data.Name, [Validators.required]],
        special: this.data.Offers[0].Description,
        amount: ["", [Validators.required]],
      });
      this.selectedSpecial = this.data.Offers[0].Id;
      this.data = null;
      this.data = this.navParams.data.data;
      this.partners = [];
      this.partners.push({
        id: this.data.Id,
        partner: this.data.Name,
      });
      this.special = [];
      this.data.Offers.forEach((element) => {
        this.special.push({
          id: element.Id,
          special: element.Description,
        });
      });
    }
  }

  validateRatingForm() {
    const formValues = this.ratingForm.value;
    this.apiService.PostCustomerOfferRating(this.customerId, parseInt(formValues.rate), formValues.rating).subscribe((x) => {});
  }

  onPartnerChange(ev) {
    const nameSelected = ev.detail.value;
    if (nameSelected) {
      if (this.mainLoyaltyCard) {
        this.data = this.alliances.find((x) => x.Name.includes(nameSelected));
        this.special = [];
        this.data.Offers.forEach((element) => {
          this.special.push({
            id: element.Id,
            special: element.Description,
          });
        });
      } else {
        this.special = [];
        this.data.Offers.forEach((element) => {
          this.special.push({
            id: element.Id,
            special: element.Description,
          });
        });
      }
    }
  }

  onSpecialChange(ev) {
    const selected = this.special.find(x=>x.special===ev.detail.value);
    this.selectedSpecial = selected.id
  }

  validateForm() {
    if (this.loyaltyForm.valid) {
      this.loyaltyForm;
      this.apiService
        .PostCustomerOffer(
          this.selectedSpecial,
          parseFloat(this.loyaltyForm.value.amount)
        )
        .subscribe((x) => {
          this.loyaltyCard = this.apiService.GetLoyaltyCardUrl();
          this.showCard = true;
          this.customerId = x;
        });
    }
  }

  generateQRCode() {
    this.generatedQrCode = true;
    this.generatedQrCodeURL = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data={{${this.customerId}}}`;
  }

  close() {
    this.modelController.dismiss();
  }
}
