import { Component, OnInit, Input } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { Plugins } from '@capacitor/core';
import { SearchRequest } from 'src/app/models/search-request';
import { PrayerTimeService } from 'src/app/services/prayer-time.service';
import { LiveStreamMasjid } from 'src/app/models/live-stream-masjid';

const { Geolocation } = Plugins;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public prayerTime: string = "";
  public iDate: string = "";
  public gDate: Date = new Date();
  public liveStreamMasjid: LiveStreamMasjid;

  @Input('title') title: string;
  @Input('showDate') showDate: boolean = false;
  @Input('dark') dark: boolean = false;
  
  constructor(
    private navController: NavController,
    private api: ApiService, 
    private prayerTimes: PrayerTimeService){

  }
  
  ngOnInit(){

    Geolocation.getCurrentPosition().then(position => {
      
      let searchRequest: SearchRequest = 
      {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        date: new Date(),
        findClosest: true
      };
      
      this.api.GetPrayerTime(searchRequest, true, false).subscribe(pt => 
      {
        this.prayerTime = this.prayerTimes.getNextPrayerTimeResult(pt, true);
      });

      this.api.GetIslamicDate().subscribe(date => {
        this.iDate = date;
      });
    });

    //TODO: Get User's Majid Code
    // this.api.GetLiveStreamMajid('mos00008').subscribe(data => {
    //   this.liveStreamMasjid = data;
    // });
  }
  
  compass(){
    this.navController.navigateForward('/compass');
  }
 
  livestream(){
    //TODO: Livestream page
  }
  
}
