import { Component } from '@angular/core';

import { Platform, NavController, MenuController, ModalController } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { environment } from 'src/environments/environment';
import { UserService } from './services/user.service';
import { EventsService } from './services/events.service';

import { Plugins, StatusBarStyle, registerWebPlugin } from '@capacitor/core';

import { FileSharer } from '@byteowls/capacitor-filesharer';


import { WelcomePage } from './pages/welcome/welcome.page';
const { SplashScreen } = Plugins;
const { StatusBar } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private events: EventsService,
    private navController: NavController,
    private api: ApiService,
    private user: UserService,
    private menu: MenuController,
    private modal: ModalController
  ) {
  }

  ngOnInit() {

    registerWebPlugin(FileSharer);
    
    if (this.platform.is('hybrid')) {
      StatusBar.setOverlaysWebView({
        overlay: false
      });

      StatusBar.setStyle({
        style: StatusBarStyle.Dark
      });
    }

    this.menu.enable(false);

    this.platform.ready().then(() => {

      if (this.platform.is('ios')) {
        localStorage['PLATFORM'] = 'ios';
      }
      else if (this.platform.is('android')) {
        localStorage['PLATFORM'] = 'android';
      }
      else {
        localStorage['PLATFORM'] = '';
      }
     
      this.api.GetVersionNumber(true).subscribe(version => {

        SplashScreen.hide();

        if (version > environment.version) {
          this.navController.navigateRoot('/update');
          //this.router.navigateByUrl('/update');
        }
        else {
          if (!localStorage['WELCOME'])
          {
            this.presentWelcomeModal();
          }

          if (this.user.isLoggedIn()) {
            this.events.userLogin(true);
            this.menu.enable(true);
          }
          else{
            this.navController.navigateRoot('/landing');
            //this.navController.navigateRoot('/register');
          }
        }
      });

    });
  }

  async presentWelcomeModal() {
    const modal = await this.modal.create({
      component: WelcomePage
    });
    return await modal.present();
  }

  
}
