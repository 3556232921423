import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { EventsService } from "src/app/services/events.service";
import {
  NavController,
  MenuController,
  NavParams,
  ModalController,
} from "@ionic/angular";
import { AlertController } from "@ionic/angular";
import { UserService } from "src/app/services/user.service";
import { LoyaltyCardComponent } from '../loyalty-card/loyalty-card.component';

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public inboxCount: number;
  constructor(
    private api: ApiService,
    private events: EventsService,
    private navController: NavController,
    private alertController: AlertController,
    private menu: MenuController,
    private user: UserService,
    private modelController: ModalController
  ) {}

  ngOnInit() {
    if (this.user.isLoggedIn()) {
      this.getUnreadMessages();
    }

    this.events.inboxCountUpdatedEvent$.subscribe((data) => {
      this.inboxCount = data;
    });

    this.events.userLoginEvent$.subscribe((data) => {
      if (data) {
        this.getUnreadMessages();
      }
    });
  }

  getUnreadMessages() {
    this.api.GetUnreadMessages(true).subscribe((data) => {
      this.inboxCount = data;
    });
  }

  goto(url: string, root: boolean = false) {
    if (root) {
      this.navController.navigateRoot(url);
    } else {
      this.navController.navigateForward(url);
    }
  }

  async logout() {
    const alert = await this.alertController.create({
      header: "Are you sure?",
      message: "Are you sure you want to log out from the application?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Log out",
          cssClass: "danger",
          handler: () => {
            this.menu.close();
            this.user.logout();
          },
        },
      ],
    });

    await alert.present();
  }

  async loyalty() {
    await this.modelController
      .create({
        component: LoyaltyCardComponent,
        componentProps: {
          data: null,
          mainLoyaltyCard: true
        }
      })
      .then((x) => {
        x.present();
      });
  }
}
