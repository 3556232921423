import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { CheckMemberRequest } from '../models/check-member';
import { ApiService } from './api.service';
//import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { NavController, Platform } from '@ionic/angular';
import { EventsService } from './events.service';


import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from '@capacitor/core';

const { PushNotifications } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private navController: NavController,
    //private firebase: FirebaseX,
    private platform: Platform,
    private api: ApiService,
    private events: EventsService
  ) {

    if (this.platform.is('hybrid')) {
      this.events.userLoginEvent$.subscribe(data => {
        if (data) {
          this.registerToken();
        }
        else {
          //this.firebase.clearAllNotifications();
          //this.firebase.unregister();
          PushNotifications.removeAllDeliveredNotifications();
          PushNotifications.removeAllListeners();
        }
      });

      this.events.inboxCountUpdatedEvent$.subscribe(amount => {
        //this.firebase.setBadgeNumber(amount);
      });
    }
  }

  isLoggedIn(): boolean {
    if (!localStorage["GUID"]) {
      return false;
    }

    if (!Guid.isGuid(localStorage["GUID"])) {
      return false;
    }

    let guid = Guid.parse(localStorage["GUID"]);

    if (guid.isEmpty()) {
      return false;
    }

    return true;
  }

  logout() {
    this.events.userLogin(false);
    localStorage.clear();
    this.navController.navigateRoot('/landing');
  }

  private registerToken() {
    if (this.platform.is('hybrid')) {

      PushNotifications.requestPermission().then(result => {
        if (result.granted) {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      PushNotifications.addListener('registration',
        (token: PushNotificationToken) => {
          console.log('Push registration success, token: ' + token.value);
          this.updateToken(token.value);
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotification) => {
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
        }
      );


      // this.firebase.hasPermission().then(permission => {
      //   if (permission)
      //   {
      //     this.firebaseEvents();
      //   }
      //   else{
      //     this.firebase.grantPermission().then(data => {
      //       if (data)
      //       {
      //         this.firebaseEvents();
      //       }
      //     })
      //   }
      // });
    }
  }

  //private firebaseEvents() {
    // this.firebase.getToken().then(token => {
    //   console.log(`firebase.getToken: ${token}`);
    //   this.updateToken(token);
    // });

    // this.firebase.onTokenRefresh().subscribe(token => {
    //   console.log(`firebase.onTokenRefresh: ${token}`);
    //   this.updateToken(token);
    // });

    // this.firebase.onMessageReceived().subscribe(data => {
    //   console.log(`firebase.onMessageReceived: ${data}`);
    // });
  //}

  private updateToken(token) {

    localStorage["FIREBASE_TOKEN"] = token;

    if (this.isLoggedIn()) {
      let data: CheckMemberRequest = {
        cell: localStorage['MSISDN'],
        deviceIdentifier: localStorage["GUID"],
        id: 0,
        platform: localStorage["PLATFORM"],
        pushId: localStorage["FIREBASE_TOKEN"],
        sendOtp: false
      };

      this.api.CheckMember(data).subscribe(data => {
        localStorage['IS_MEMBER'] = data.isMember;
        localStorage['IS_SAME_DEVICE'] = data.isSameDevice;
        localStorage['NEW_REGISTRATION'] = data.newRegistration;
        localStorage['IS_TEMP_USER'] = data.isTempUser;
      });
    }
  }

}
