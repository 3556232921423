import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor(private loadingController: LoadingController) {
  }

  async present(message: string = 'Please wait...') {
    const loading = await this.loadingController.create({
      message: message,
    });

    await loading.present();
  }

  dismiss() {
    this.loadingController.dismiss().then(x => console.log(x));
  }
}
