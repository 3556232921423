<ion-content>
  <ion-grid class="h-100 ion-no-padding" style="display: flex; flex-flow: column; min-height: 500px;">
    <ion-row class="ion-text-center ion-align-items-start">
      <ion-col class="ion-padding-top">
        <ion-text>
          <h1>Welcome to</h1>
        </ion-text>
        <img src="/assets/img/crescent_lifestyle_logo.svg" class="img-fluid" />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-padding-top">
        <br/><br/><br/>
      </ion-col>
    </ion-row>
    <ion-row class="ion-margin-top ion-text-center ion-align-items-stretch" style="flex-grow: 1;">
      <ion-col class="ion-text-center ion-align-items-stretch"  style="display: flex; flex-grow: 1; margin-top: -30px">
          <ion-slides pager="true" [options]="slideOptions">
            <ion-slide>
              <div class="tile tile-1 ion-align-items-center ion-justify-content-center">
                <img src="/assets/img/connect.svg"/>
              </div>
              <h1>CONNECT</h1>
              <p>Celebrating your life of sharing</p>
            </ion-slide>

            <ion-slide>
              <div class="tile tile-2 ion-align-items-center ion-justify-content-center">
                <img src="/assets/img/save.svg"/>
              </div>
              <h1>SAVE</h1>
              <p>Celebrating your lifestyle of saving money</p>
            </ion-slide>

            <ion-slide>
              <div class="tile tile-3 ion-align-items-center ion-justify-content-center">
                <img src="/assets/img/win.svg"/>
              </div>
              <h1>WIN</h1>
              <p>Celebrating your life of Islam</p>
            </ion-slide>

            <ion-slide>
              <div class="tile tile-4 ion-align-items-center ion-justify-content-center">
                <img src="/assets/img/give.svg"/>
              </div>
              <h1>GIVE</h1>
              <p>Celebrating your life of giving</p>
            </ion-slide>
          </ion-slides>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer class="ion-text-center ion-padding">
  <ion-button (click)="skip()" fill="clear" color="medium">SKIP</ion-button>
</ion-footer>